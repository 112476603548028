/**{*/
/*  !*font-family: "Times New Roman", Times, serif;*!*/
/*  font-size: 1.1em;*/
/*}*/
*{
  font-family: "Segoe UI", sans-serif;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}
h1{font-size: 2.8em;}
h2{font-size: 2.4em;}
h3{font-size: 1.8em;}

h4,h5{font-size: 1.5em;}

h6{font-size: 1.1em;}

a{font-size: 1.1em;}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
